@import 'styles/theme.scss';

.cookieConsent {
  background-color: $primary-dark !important;
  padding: 0 28px;
}

.cookieIcon {
  vertical-align: text-bottom;
  font-size: 18px !important;
  margin-right: 10px;
}

.cookieButton {
  background-color: transparent !important;
  color: $white !important;
  text-decoration: underline;
  font-weight: $font-weight-400;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 15px 0 !important;
  padding: 5px 8px !important;

  @media (min-width: $sm) {
    margin: 15px 0 !important;
    font-size: 16px;
  }
}

.cookieContent {
  color: $white;
  font-weight: $font-weight-400;
  font-size: 14px;
  line-height: 24px;
  margin: 12px 8px !important;

  @media (min-width: $sm) {
    font-size: 16px;
  }
}
